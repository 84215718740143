<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import moment from 'moment'
  import axios from "axios";

  export default {
    page: {title: "EINSTUDIO-Users",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader
    },data() {
      return {
        title: "USERS",
        items: [{text: "Home",href:"#"}, {text: "Users",active: true}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,fileArray:[],loginUser:[],user:{
              userName: "",  mail: "",  password: "", repassword:"", userGroupId:"",
              name:"", lastName:"", phone:"", country:"", city:"", school:""
              , section:"", classRoom:"", address:"", fullName:"",status:true},filter:''
      };
      },computed: {
      filteredrows(){
        return this.fileArray.filter(row=>{
          const type=row.userGroup.name.toString().toLowerCase();
          const username= row.fullName.toString().toLowerCase();
          const searchTerm= this.filter.toLowerCase();
          return type.includes(searchTerm) || username.includes(searchTerm);
        })
      }
      },created:function(){
        this.loginUser = JSON.parse(localStorage.getItem('user'));
        this.loadData();
      },methods: {
         addData(){
         let loader = this.$loading.show();
         axios.post(`${this.baseUrl}/Auth/Register`, this.user)
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
          this.hideModal('modal-lg');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },loadData(){
          let url= this.baseUrl+"/User/GetAll";
          axios.get(url,{
            headers:{ Authorization: `Bearer ${this.loginUser.jwt}`}
          }).then((response)=>{
              this.fileArray= response.data;
              this.fileArray= this.fileArray.filter(x=>x.userGroup.name!='admin')
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },format_date(value)
      {
         if (value) 
         {
           return moment(String(value)).format('MM.DD.YYYY HH:mm')
          }
      },DeleteConfirm(id){
            this.$swal({ 
            title: "WARNING",
            text: "Are you sure? This data will be delete.",
            //type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes",
            cancelButtonText:"No",
            }).then((result) => {
                if (result.value) 
                {
                  this.deleteData(id)
                }
            });
      },deleteData(id){
            let loader= this.$loading.show();
            axios.delete(`${this.baseUrl}/User/Delete/`+id, {
                headers: { Authorization: `Bearer ${this.loginUser.jwt}`}
            }).then((response) => 
            {
             loader.hide();
             this.$swal("",response.data.message, "success");
             this.loadData();
            })
            .catch((error) => 
            {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
            });
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
     }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3" v-if="this.loginUser.rol=='admin'">
        <div class="col-lg-12">
          <a type="button" id="add-modal"  v-b-modal.modal-lg  class="btn btn-success"><i class="fa fa-edit"></i> + ADD USER</a>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
               <div class="card-body">
                <input type="text" name="" id="" placeholder="filter by type or member name" v-model="filter" class="form-control">
                <table id="tech-companies-1" class="table table-striped">
                
            <thead>
                <tr>
                    <th>ID/Number</th>
                    <th>Type</th>
                    <th>Name Surname</th>
                    <th>mail</th>
                    <th>school</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in filteredrows" :key="index">
                    <th>{{item.id}}</th>
                    <th>{{item.userGroup.name}}</th>
                    <td>{{ item.fullName }}</td>
                    <td>{{ item.mail }}</td>
                    <td>{{item.school}}</td>
                    <td>
                        <button v-b-tooltip.hover title="Delete" type="button" class="btn btn-danger btn-sm mr-1" @click="DeleteConfirm(item.id)"><i class="fa fa-trash"></i></button>
                        <router-link v-b-tooltip.hover title="User Detail" :to="{ path: '/userprofile/'+item.id}" class="btn btn-sm btn-info ml-1"><i class="fa fa-user"></i></router-link>
                    </td>
                </tr>
            </tbody>
        </table>
               </div>
            </div>
           
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" title="New Member" title-class="font-18" hide-footer>
        <form class="form-horizontal" @submit.prevent="addData()" id="add-file-form">
                        <div class="row">
                        <div class="col-lg-4">

                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-users auti-custom-input-icon"></i>
                            <label for="userGroupId">User Group Type</label>
                            <select v-model="user.userGroupId" class="form-control" id="userGroupId" name="userGroupId">
                                <option value="" selected>Please select one</option>
                                <option value="2">TEACHER</option>
                                <option value="3">STUDENT</option>
                            </select>
                            </div>

                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-id-card auti-custom-input-icon"></i>
                            <label for="firstname">First Name</label>
                            <input v-model="user.name" name="name"
                                type="text"
                                class="form-control"
                                id="firstname"
                                placeholder="Enter First Name">
                            </div>

                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-id-card auti-custom-input-icon"></i>
                            <label for="lastname">Last Name</label>
                            <input  v-model="user.surName" name="surName"
                                type="text"
                                class="form-control"
                                id="lastname"
                                placeholder="Enter Last Name"/>
                            </div>

                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-phone auti-custom-input-icon"></i>
                            <label for="name">Phone</label>
                            <input v-model="user.phone" name="prhone"
                                type="text"
                                class="form-control"
                                id="phone"
                                placeholder="Enter Last Name"/>
                            </div>

                        </div>
                        <div class="col-lg-4">

                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-globe auti-custom-input-icon"></i>
                            <label for="name">Country</label>
                            <input @input="(val) => (user.country = user.country.toUpperCase())" v-model="user.country" name="country"
                                type="text"
                                class="form-control"
                                id="country"
                                placeholder="Enter Country Name"
                                maxlength="30"
                                max="30" />
                            </div>

                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-map-marker auti-custom-input-icon"></i>
                            <label for="city">City</label>
                            <input @input="(val) => (user.city = user.city.toUpperCase())" name="city"
                                v-model="user.city"
                                type="text"
                                class="form-control"
                                id="city"
                                placeholder="Enter City Name"
                                maxlength="30"
                                max="30"/>

                            </div>

                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-school auti-custom-input-icon"></i>
                            <label for="school">School</label>
                            <input @input="(val) => (user.school = user.school.toUpperCase())" name="school"
                                v-model="user.school"
                                type="text"
                                class="form-control"
                                id="school"
                                placeholder="Enter School Name"
                                maxlength="30"
                                max="30"/>
                            </div>

                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-id-card auti-custom-input-icon"></i>
                            <label for="title">Title</label>
                            <input @input="(val) => (user.title = user.title.toUpperCase())" name="title"
                                v-model="user.title"
                                type="text"
                                class="form-control"
                                id="title"
                                placeholder="What is your academic title?"
                                maxlength="30"
                                max="30"/>
                            </div>
                            
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-user auti-custom-input-icon"></i>
                            <label for="username">Username</label>
                            <input v-model="user.userName" name="userName"
                            type="text"
                            class="form-control"
                            id="username"
                            maxlength="20"
                            max="20" placeholder="Enter username"/>
                            </div>
                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-envelope auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                                v-model="user.mail" name="mail"
                                type="email"
                                class="form-control"
                                id="useremail"
                                placeholder="Enter email"/>

                            </div>
                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-lock auti-custom-input-icon"></i>
                            <label for="password">Password</label>
                            <input
                                v-model="user.password" name="password"
                                type="password"
                                class="form-control"
                                id="password"
                                placeholder="Enter password"
                                maxlength="10"
                                minlength="8"
                                max="10"
                                min="8" />
                            </div>
                            <div class="form-group auth-form-group-custom mb-4">
                            <i class="fa fa-lock auti-custom-input-icon"></i>
                            <label for="userpassword">Confirm Password</label>
                            <input
                                v-model="user.repassword" name="repassword"
                                type="password"
                                class="form-control"
                                id="repassword"
                                placeholder="Enter re-password"
                                maxlength="10"
                                minlength="8"
                                max="10"
                                min="8" />
                            </div>
                        </div>
                        </div>

                        <div class="text-center">
                        <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Register</button>
                        </div>
                    </form>
    </b-modal>
  </Layout>
</template>